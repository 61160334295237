<template>
  <div class="convert-mobile">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card elevation="0">
            <v-card-text>
              <div class="title">
                <div class="center">
                  <img src="img/home/bcv.png" alt="bcv" />
                  <div class="text">Banco Central de Venezuela</div>
                </div>
              </div>

              <div class="content">
                <div class="center" v-if="convert">
                  <div class="input-icon">
                    <div>$</div>
                    <money
                      v-model="dolar"
                      v-bind="settingDolar"
                      @change.native="onChangeBs()"
                      @click.native="onClickDolar()"
                    ></money>
                  </div>

                  <div class="input-icon opacity">
                    <div>Bs</div>
                    <money v-model="bs" v-bind="settingBs" disabled></money>
                  </div>
                </div>

                <div class="center" v-if="!convert">
                  <div class="input-icon">
                    <div>Bs</div>
                    <money
                      v-model="bs"
                      v-bind="settingBs"
                      @change.native="onChangeDolar()"
                      @click.native="onClickBs()"
                    ></money>
                  </div>

                  <div class="input-icon opacity">
                    <div>$</div>
                    <money
                      v-model="dolar"
                      v-bind="settingDolar"
                      disabled
                    ></money>
                  </div>
                </div>

                <div class="btn-convert">
                  <v-btn
                    color="second"
                    class="white--text"
                    @click="btnConvert()"
                  >
                    <v-icon>mdi-swap-horizontal</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { Money } from "v-money";

export default {
  name: "Convert",
  data: () => ({
    dolar: 1,
    bs: 0,
    convert: true,
    valueDolar: false,
    valueBs: false,
    settingDolar: {
      decimal: ".",
      thousands: ",",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
    },
    settingBs: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
    },
  }),
  components: { Money },
  created() {
    this.bs = this.formatMoneda();
  },
  methods: {
    formatMoneda() {
      let moneda = this.$store.state.setting.moneda;
      const arrayMoneda = moneda.split(".");
      if (arrayMoneda.length > 2) {
        moneda = moneda.split(".").join("");
        moneda = moneda.replace(",", ".");
        moneda = parseFloat(moneda);
      } else if (moneda.indexOf(",") !== -1) {
        moneda = moneda.replace(",", ".");
        moneda = parseFloat(moneda);
      } else {
        moneda = parseFloat(moneda);
      }

      return moneda.toFixed(2);
    },
    onChangeBs() {
      const moneda = this.formatMoneda();
      const result = this.dolar * moneda;
      this.bs = result;
    },
    onChangeDolar() {
      const moneda = this.formatMoneda();
      const result = this.bs / moneda;
      this.dolar = result;
    },
    btnConvert() {
      this.convert = this.convert ? false : true;
      this.dolar = 1;
      this.bs = this.formatMoneda();
    },
    onClickDolar() {
      this.dolar = 0;
    },
    onClickBs() {
      this.bs = 0;
    },
  },
};
</script>

<style lang="scss">
.convert-mobile {
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    padding: 5px;

    .center {
      text-align: center;
      img {
        width: 90px;
      }
    }

    .text {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      color: #193c71;
    }

    .info {
      text-align: center;
      margin-top: 5px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #193c71;

      ion-icon {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  .content {
    margin-top: 10px;
    padding: 16px;
    .center {
      .input-icon {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        color: var(--color-second);
        margin-bottom: 10px;
        div {
          position: absolute;
        }
      }

      input {
        color: var(--color-second);
        width: 100%;
        text-align: right;
        border-bottom: solid 1px #dedede;
        padding: 10px;
        font-size: 18px;
      }

      .opacity {
        opacity: 0.8;
      }
    }

    .btn-convert {
      text-align: center;
      margin-top: 30px;

      .v-btn {
        height: 50px;
        min-width: 50px;

        i {
          font-size: 35px;
        }
      }
    }
  }
}
</style>
